<template>
  <div class="container mx-auto px-3 lg:p-0">
    <h3 class="page-title mb-5">My Account</h3>
    <div class="card">
      <div class="px-3">
        <h3 class="page-subtitle">User Profile</h3>
      </div>
      <form action="#" @submit.prevent="postMyProfile" class="mb-5">
        <div class="lg:flex">
          <div class="lg:w-6/12 px-3">
            <div
              class="form-group"
              :class="{ error: v$.form.FirstName.$errors.length }"
            >
              <label>First Name <span class="required-star">*</span></label>
              <input
                type="text"
                class="form-control"
                v-model="v$.form.FirstName.$model"
              />
              <div
                class="input-errors"
                v-for="(error, index) of v$.form.FirstName.$errors"
                :key="index"
              >
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>
          </div>
          <div class="lg:w-6/12 px-3">
            <div
              class="form-group"
              :class="{ error: v$.form.LastName.$errors.length }"
            >
              <label>Last Name <span class="required-star">*</span></label>
              <input
                type="text"
                class="form-control"
                v-model="v$.form.LastName.$model"
              />
              <div
                class="input-errors"
                v-for="(error, index) of v$.form.LastName.$errors"
                :key="index"
              >
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="lg:flex">
          <div class="lg:w-6/12 px-3">
            <div
              class="form-group"
              :class="{ error: v$.form.PhoneNumber.$errors.length }"
            >
              <label>Phone <span class="required-star">*</span></label>
              <input
                type="text"
                class="form-control"
                @input="acceptPhoneNumber()"
                v-model="v$.form.PhoneNumber.$model"
              />
              <div
                class="input-errors"
                v-for="(error, index) of v$.form.PhoneNumber.$errors"
                :key="index"
              >
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>
          </div>
          <div class="lg:w-6/12 px-3">
            <div
              class="form-group"
              :class="{ error: v$.form.EmailAddress.$errors.length }"
            >
              <label>Email <span class="required-star">*</span></label>
              <input
                type="text"
                class="form-control"
                v-model="v$.form.EmailAddress.$model"
              />
              <div
                class="input-errors"
                v-for="(error, index) of v$.form.EmailAddress.$errors"
                :key="index"
              >
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="px-3">
          <button class="main-btn">Save User Profile</button>
        </div>
      </form>
      <form action="#" @submit.prevent="postMyPassword" class="change-password">
        <div class="px-3">
          <h3 class="page-subtitle">Change Password</h3>
        </div>
        <div class="lg:flex">
          <div class="lg:w-4/12 px-3">
            <div class="form-group">
              <label>Old Password <span class="required-star">*</span></label>
              <input
                type="password"
                class="form-control"
                v-model="v$.form2.oldpassword.$model"
              />
              <div
                class="input-errors"
                v-for="(error, index) of v$.form2.oldpassword.$errors"
                :key="index"
              >
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>
          </div>
          <div class="lg:w-4/12 px-3">
            <div class="form-group">
              <label>New Password <span class="required-star">*</span></label>
              <input
                type="password"
                class="form-control"
                ref="password"
                v-model="v$.form2.password.$model"
              />
              <div
                class="input-errors"
                v-for="(error, index) of v$.form2.password.$errors"
                :key="index"
              >
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>
          </div>
          <div class="lg:w-4/12 px-3">
            <div class="form-group">
              <label
                >Confirm Password <span class="required-star">*</span></label
              >
              <input
                type="password"
                ref="confirmpassword"
                class="form-control"
                v-model="form2.confirmpassword"
              />
              <div class="input-errors">
                <div class="error-msg">{{ samepassworderror }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="px-3">
          <button class="main-btn">Save Password</button>
        </div>
      </form>
      <div v-if="message !== null" class="px-3">
        <div class="success-msg" :class="{ eroormsg: !isSuccessfull }">
          {{ message }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { dataProvider } from "@/api/dataProvider";
import useVuelidate from "@vuelidate/core";
import {
  required,
  helpers,
  email,
  alpha,
  requiredIf,
  minLength,
} from "@vuelidate/validators";
export default {
  data() {
    return {
      v$: useVuelidate(),
      form: {
        CarrierPortalUserID: this.$store.state.login.CarrierPortalUserID,
        FirstName: this.$store.state.login.FirstName,
        LastName: this.$store.state.login.LastName,
        PhoneNumber: this.$store.state.login.PhoneNumber,
        EmailAddress: this.$store.state.login.EmailAddress,
        SubscribeToELoad: true,
        oldpassword: null,
        password: null,
        confirmpassword: null,
      },
      form2: {
        CarrierPortalUserID: this.$store.state.login.CarrierPortalUserID,
        FirstName: this.$store.state.login.FirstName,
        LastName: this.$store.state.login.LastName,
        PhoneNumber: this.$store.state.login.PhoneNumber,
        EmailAddress: this.$store.state.login.EmailAddress,
        SubscribeToELoad: true,
        oldpassword: null,
        password: null,
        confirmpassword: null,
      },
      isSuccessfull: false,
      message: null,
      samepassworderror: "",
    };
  },
  validations() {
    return {
      form: {
        FirstName: {
          required: helpers.withMessage("Firstname is required", required),
          alpha: helpers.withMessage("Firstname should be a text", alpha),
        },
        LastName: {
          required: helpers.withMessage("Lastname is required", required),
          alpha: helpers.withMessage("Lastname should be a text", alpha),
        },
        PhoneNumber: {
          required: helpers.withMessage("Phone Number is required", required),
          minLength: helpers.withMessage(
            "Please enter a valid phone number",
            minLength(14)
          ),
        },
        EmailAddress: {
          required: helpers.withMessage("Email Address is required", required),
          email: helpers.withMessage("Please enter a valid email", email),
        },
      },
      form2: {
        oldpassword: {
          requiredIf: helpers.withMessage(
            "Old Password is required",
            requiredIf("password")
          ),
        },
        password: {
          requiredIf: helpers.withMessage(
            "New Password is required",
            requiredIf("oldpassword")
          ),
        },
      },
    };
  },
  methods: {
    acceptPhoneNumber() {
      var x = this.form.PhoneNumber.replace(/\D/g, "").match(
        /(\d{0,3})(\d{0,3})(\d{0,4})/
      );
      this.form.PhoneNumber = !x[2]
        ? x[1]
        : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
    },
    async postMyProfile() {
      this.v$.form.$touch();
      if (this.v$.form.$error) return;

      var response = await dataProvider.postMyProfile(
        this.form.CarrierPortalUserID,
        this.form.FirstName,
        this.form.LastName,
        this.form.PhoneNumber,
        this.form.EmailAddress,
        this.form.SubscribeToELoad,
        this.form.oldpassword,
        this.form.password
      );

      this.message = response.ResponseText;

      this.isSuccessfull = response.IsSuccessful;
      setTimeout(() => (this.message = null), 3000);
    },
    async postMyPassword() {
      this.v$.form2.$touch();
      if (this.v$.form2.$error) return;
      if (this.$refs.password.value != this.$refs.confirmpassword.value) {
        this.samepassworderror =
          "Confirm password must be same as new password";
        setTimeout(() => (this.samepassworderror = ""), 3000);
        return;
      }
      var response = await dataProvider.postMyProfile(
        this.form.CarrierPortalUserID,
        this.form.FirstName,
        this.form.LastName,
        this.form.PhoneNumber,
        this.form.EmailAddress,
        this.form2.SubscribeToELoad,
        this.form2.oldpassword,
        this.form2.password
      );
      this.message = response.ResponseText;
      this.isSuccessfull = response.IsSuccessful;
      setTimeout(() => (this.message = null), 3000);
    },
  },
};
</script>
<style lang="scss" scoped>
.card {
  margin-bottom: 20px;
}

.page-subtitle {
  font-size: 18px;
  font-weight: 700;
  color: #65b0ba;
  margin-bottom: 10px;
}
.success-msg {
  background: #5ad568ee;
  color: #fff;
  padding: 10px 20px;
  text-align: center;
  border-radius: 6px;
  margin-top: 10px;
  &.eroormsg {
    background: #e9664d;
  }
}
.form-group {
  margin-bottom: 20px;
  &.error {
    .form-control {
      border: 1px solid #ff073a;
      margin-bottom: 5px;
    }
  }
}
.input-errors {
  font-size: 14px;
  color: #ff073a;
}
.change-password {
  .form-control {
    margin-bottom: 5px;
  }
}
</style>
